<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">巴林右旗土地流转信息发布</div> 
    </div>

    <div class="content_box">
      <div class="left_box">
        <div class="title"><span>土地流转列表</span></div>

        <div class="list_box">
          <div class="item" v-for="(item, index) in list" :key="index" @click="clickItem(index)">
            <div class="mb_5">所在地：{{ item.hamlet }}</div> 
            <div class="mb_5 font_number text_left">地块号：{{ item.num }}</div> 
            <div class="mb_5" @click="clickVillageName(item.name)">
              承包人：{{ item.name }}
            </div> 
            <div class="mb_5">土地用途：{{ item.val }}</div> 
          </div>
        </div>
      </div> 

      <div class="right_box">
        <div class="map_box">
          <ba-lin-you-qi-map @changeVillage="changeVillage" />
          <!-- <da-ban-map v-if="hideLargeMap && villageName == '大板镇'" />
          <su-bo-ri-ga v-if="hideLargeMap && villageName == '索博日嘎镇'" />
          <xing-fu-zhi-lu v-if="hideLargeMap && villageName == '幸福之路苏木'" />
          <ba-yan-hu-shuo-hamlet v-if="hideLargeMap && villageName == '巴彦琥硕镇'" />
          <cha-gan-mu-lun-hamlet v-if="hideLargeMap && villageName == '查干沐沦苏木'" />
          <mei-dian-hua v-if="hideLargeMap && villageName == '大板煤电化基地'" />

          <ba-yan-ta-la v-if="hideLargeMap && villageName == '巴彦塔拉苏木'" />
          <cha-gan-nuo-er v-if="hideLargeMap && villageName == '查干诺尔镇'" />
          <bao-ri-wu-su v-if="hideLargeMap && villageName == '宝日勿苏镇'" />
          <xi-la-mu-lun v-if="hideLargeMap && villageName == '西拉沐沦苏木'" /> -->

        </div>
        <div class="ba_chart">
          <zhu-zhaung-tu :value="data1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import BingTu from '../charts/bing-tu.vue'

  import DaBanMap from '../maps/daBanMap.vue'
  import SuBoRiGa from '../maps/suBoRiGa.vue'
  import XingFuZhiLu from '../maps/xingFuZhiLu.vue'
  import BaYanHuShuoHamlet from '../maps/ba-yan-hu-shuo-hamlet.vue'
  import ChaGanMuLunHamlet from '../maps/cha-gan-mu-lun-hamlet.vue'
  import MeiDianHua from '../maps/mei-dian-hua.vue'
  import BaYanTaLa from '../maps/ba-yan-ta-la.vue'
  import BaoRiWuSu from '../maps/bao-ri-wu-su.vue'
  import XiLaMuLun from '../maps/xi-la-mu-lun.vue'
  import ChaGanNuoEr from '../maps/cha-gan-nuo-er.vue'
  import kongXinBingTu from '../charts/kong-xin-bing-tu.vue'
  import ZhuZhaungTu from '../charts/zhu-zhaung-tu-tu-di.vue'

  export default {
    name: 'showPage1',
    components: { 
      BaLinYouQiMap, 
      BingTu, 
      DaBanMap, 
      SuBoRiGa, 
      XingFuZhiLu, 
      BaYanHuShuoHamlet,
      ChaGanMuLunHamlet,
      MeiDianHua,
      BaYanTaLa,
      BaoRiWuSu,
      XiLaMuLun,
      ChaGanNuoEr, 
      kongXinBingTu,
      ZhuZhaungTu,
    },
    data() {
      return {
        activeIndex: 0,
        title: '大板镇土地流转变化图',
        villageName: '',
        data1: {},

        list: [
          {
            name: "哈斯巴根",
            num: "10001",
            hamlet: "大板镇浩饶沁嘎查",
            val: '耕地'
          },
          {
            name: "哈斯其木格",
            num: "1833",
            hamlet: "大板镇大冷村",
            val: '耕地'
          },
          {
            name: "斯琴",
            num: "41415118",
            hamlet: "大板镇西哈嘎查",
            val: '耕地'
          }
        ],

        list1: [
          {
            name: "其木格",
            num: "4231",
            hamlet: "索博日嘎镇索博日嘎嘎查",
            val: '耕地'
          },
          {
            name: "巴特尔",
            num: "3144",
            hamlet: "索博日嘎镇常兴村",
            val: '耕地'
          },
          {
            name: "满达",
            num: "8976",
            hamlet: "索博日嘎镇黑山头村",
            val: '耕地'
          },
          {
            name: "巴彦",
            num: "2345",
            hamlet: "索博日嘎朝阳村",
            val: '耕地'
          }
        ],

        list2: [
          {
            name: "宝音",
            num: "1345",
            hamlet: "幸福之路苏木敖日盖嘎查",
            val: '耕地'
          },
          {
            name: "新其其格",
            num: "2189",
            hamlet: "幸福之路苏木塔拉艾力",
            val: '耕地'
          },
          {
            name: "乌力吉",
            num: "41412435",
            hamlet: "幸福之路苏木希热阿尔",
            val: '耕地'
          },
          {
            name: "哈斯巴根",
            num: "10001",
            hamlet: "幸福之路苏木阿里木吐嘎查",
            val: '耕地'
          },
        ],
        list3: [
          {
            name: "巴达拉",
            num: "4312",
            hamlet: "巴彦琥硕镇四家村",
            val: '耕地'
          },
          {
            name: "哈申",
            num: "5321",
            hamlet: "巴彦琥硕镇中组村",
            val: '耕地'
          },
          {
            name: "海山",
            num: "3213",
            hamlet: "巴彦琥硕镇巴彦琥硕村",
            val: '耕地'
          }
        ],
        list4: [
          {
            name: "巴图",
            num: "4212",
            hamlet: "查干沐沦苏木塔本花嘎查",
            val: '耕地'
          },
          {
            name: "胡日差",
            num: "3212",
            hamlet: "查干沐沦苏木朱拉沁嘎查",
            val: '耕地'
          },
          {
            name: "宝乐格",
            num: "3213",
            hamlet: "查干沐沦苏木呼苏宝冷",
            val: '耕地'
          }
        ],

        list5: [
          {
            name: "哈斯巴根",
            num: "10001",
            hamlet: "大板镇浩饶沁嘎查",
            val: '耕地'
          },
          {
            name: "哈斯其木格",
            num: "1833",
            hamlet: "大板镇大冷村",
            val: '耕地'
          },
          {
            name: "斯琴",
            num: "41415118",
            hamlet: "大板镇西哈嘎查",
            val: '耕地'
          }
        ],
      }
    },
    mounted() {
      setTimeout(() => {
        this.initChart()
      }, 200)
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: {
            text: '大板镇土地流转变化图',
            left: "20px",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '千亩',
          x: [
            '2022年5月', 
            '6月', 
            '7月', 
            '8月', 
            '9月', 
            '10月', 
            '11月', 
            '12月', 
            '2023年1月',
            '2月',
            '3月',
            '4月',
          ],
          y: [4.44, 0.66, 15.5, 10.64, 1.32, 8.88, 14.88, 13.1, 13.54, 6.92, 8.66, 13.32]
        }
      },
      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      // 监听地图点击
      changeVillage(val) {
        this.villageName = val 

        if (val == '索博日嘎镇') {
          this.list = this.list1

          this.data1 = {
            title: {
              text: '幸福之路苏木土地流转变化图',
            },
            yName: '千亩',
            x: [ '2022年5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月', '2023年1月', '2月', '3月', '4月'],
            y: [4.32, 2.56, 10.5, 8.64, 1.32, 8.68, 4.88, 4.7, 6.54, 6.32, 4.26, 9.32]
          }
        } else if (val == '幸福之路苏木') {
          this.list = this.list2
          this.data1 = {
            title: {
              text: '幸福之路苏木土地流转变化图',
            },
            yName: '千亩',
            x: [ '2022年5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月', '2023年1月', '2月', '3月', '4月'],
            y: [3.32, 6.56, 9.5, 3.64, 5.32, 7.68, 3.58, 5.76, 3.14, 4.32, 4.26, 9.32]
          }
        } else if (val == '巴彦琥硕镇') {
          this.list = this.list3
          this.data1 = {
            title: {
              text: '巴彦琥硕镇土地流转变化图',
            },
            yName: '千亩',
            x: [ '2022年5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月', '2023年1月', '2月', '3月', '4月'],
            y: [4.32, 3.56, 11.5, 5.64, 5.32, 8.68, 3.58, 7.76, 5.14, 6.32, 7.26, 9.32]
          }
        } else if (val == '查干沐沦苏木') {
          this.list = this.list4

          this.data1 = {
            title: {
              text: '查干沐沦苏木土地流转变化图',
            },
            yName: '千亩',
            x: [ '2022年5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月', '2023年1月', '2月', '3月', '4月'],
            y: [5.32, 6.56, 8.5, 3.64, 2.32, 8.68, 6.58, 4.76, 3.14, 6.32, 7.26, 9.32]
          }
        } else if (val == '大板镇') {
          this.list = this.list5
          this.data1.title.text = '大板镇土地流转变化图'
        } else if (val == '大板煤电化基地') {
          this.list = this.list6
          this.data1.title.text = '大板煤电化基地土地流转变化图'
        } else if (val == '巴彦塔拉苏木') {
          this.list = this.list7
          this.data1.title.text = '巴彦塔拉苏木土地流转变化图'
        } else if (val == '查干诺尔镇') {
          this.list = this.list8
          this.data1.title.text = '查干诺尔镇土地流转变化图'
        } else if (val == '宝日勿苏镇') {
          this.list = this.list9
          this.data1.title.text = '宝日勿苏镇土地流转变化图'
        } else if (val == '西拉沐沦苏木') {
          this.list = this.list10
          this.data1.title.text = '西拉沐沦苏木土地流转变化图'
        }
      },

      // 点击 item
      clickItem(index) {
        let villageName = this.villageName

      }
    }
  }
</script>

<style scoped lang="less">
  @import url('../../../../assets/css/bigscreen.less');
  .activeColor {color: rgb(13, 246, 94) !important; }


  .left_box {
    width: 33%;
    height: 100% !important;

    .title { 
      width: 100%;
      height: 5%;
      text-align: center;
      position: relative;
      
      span {
        font-weight: bold;
        font-size: 18px;
        color: #82fefe;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .list_box {
      width: 100%;
      height: 90%;
      overflow: auto;
      padding: 10px;

      .item {
        width: 100%;
        font-size: 20px;
        color: #fff;
        padding: 10px;
        border: 1px solid #fff;
        border-radius: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        // background-color: #2567f6;

        span { width: 60px; }
        .name { width: 30%; cursor: pointer; }   
        .value1 { width: 25%; }
        .value2 { width: 25%; }
        .value3 { width: 20%; }
        .value4 { width: 20%; }
      }
    }
    .list_box::-webkit-scrollbar {
      width: 0px;
      height: 100%;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {                 // 表示纵向滚动块
      border-radius: 10px;
      background: #2366fc;
    }
  }

  .right_box {
    width: 65%;
    height: 100%;
    position: relative;

    .map_box {
      width: 100%;
      height: 100%;
      position: absolute;
      left: -20%;
      .show_map_btn { cursor: pointer; }
    }

    .ba_chart {
      width: 60%;
      height: 50%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }  

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>